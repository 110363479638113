import React, { useRef } from 'react'
import './Join.css'
import emailjs from "@emailjs/browser"

const Join = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_3kp1yga', 'template_gk1hgxi', form.current, '6q7UUQg6Qj5fz_hq-')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className='Join'id='join-us'>
        <div className="left-j">
            <hr />
            <div>
                <span>Ready To</span>
                <span className='stroke-text'>Level Up</span>
            </div>
            <div>
                <span className='stroke-text'>Your Body</span>
                <span>With Us</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className='email-container' onSubmit={sendEmail}>
                <input type="email" name='user_email' placeholder='Enter your Email address' />
                <button className='btn btn-j'>Join Now</button>
            </form>

        </div>
    </div>
  )
}

export default Join